const cards = [
   {
      id: "img01",
      target: "",
      alt: "Corda Naútica azul marinho",
      title: "Corda Naútica",
      description: "Cor: Azul marinho",
   },
   {
      id: "img02",
      target: "",
      alt: "Corda Naútica grafite",
      title: "Corda Naútica",
      description: "Cor: Grafite",
   },
   {
      id: "img03",
      target: "",
      alt: "Corda Naútica preta",
      title: "Corda Naútica",
      description: "Cor: Preta",
   },
   {
      id: "img04",
      target: "",
      alt: "Corda Naútica rami",
      title: "Corda Naútica",
      description: "Cor: Rami",
   },
   {
      id: "img05",
      target: "",
      alt: "Corda Naútica verde",
      title: "Corda Naútica",
      description: "Cor: Verde",
   },
   {
      id: "img06",
      target: "",
      alt: "Cordão Sintético cinza",
      title: "Cordão Sintético",
      description: "Cor: Cinza",
   },
   {
      id: "img07",
      target: "",
      alt: "Alumínio de cor Cinza",
      title: "Alumínio de cor Cinza",
      description: "",
   },
   {
      id: "img08",
      target: "",
      alt: "Alumínio pintado de cor Branca",
      title: "Alumínio pintado de cor Branca",
      description: "",
   },
   {
      id: "img09",
      target: "",
      alt: "Alumínio pintado de cor Marrom",
      title: "Alumínio pintado de cor Marrom",
      description: "",
   },
   {
      id: "img10",
      target: "",
      alt: "Alumínio Polido",
      title: "Alumínio Polido",
      description: "",
   },
   {
      id: "img11",
      target: "",
      alt: "Alumínio pintado de cor Preta",
      title: "Alumínio pintado de cor Preta",
      description: "",
   },
   {
      id: "img12",
      target: "",
      alt: "Tecido Acquabloc Folhagem",
      title: "Tecido Acquabloc Folhagem",
      description: "",
   },
   {
      id: "img13",
      target: "",
      alt: "Tecido Acquabloc Garcínia",
      title: "Tecido Acquabloc Garcínia",
      description: "",
   },
   {
      id: "img14",
      target: "",
      alt: "Tecido Acquabloc Maranta Bege",
      title: "Tecido Acquabloc Maranta Bege",
      description: "",
   },
   {
      id: "img15",
      target: "",
      alt: "Tecido Acquabloc Mariscal",
      title: "Tecido Acquabloc Mariscal",
      description: "",
   },
   {
      id: "img16",
      target: "",
      alt: "Tecido Acquabloc Pause",
      title: "Tecido Acquabloc Pause",
      description: "",
   },
   {
      id: "img17",
      target: "",
      alt: "Tecido Acquabloc Rafia",
      title: "Tecido Acquabloc Rafia",
      description: "",
   },
   {
      id: "img18",
      target: "",
      alt: "Tecido Acquabloc Silence",
      title: "Tecido Acquabloc Silence",
      description: "",
   },
   {
      id: "img19",
      target: "",
      alt: "Tecido Acquabloc Saquarena",
      title: "Tecido Acquabloc Saquarena",
      description: "",
   },
   {
      id: "img20",
      target: "",
      alt: "Tecido Aspargo Natural",
      title: "Tecido Aspargo Natural",
      description: "",
   },
   {
      id: "img21",
      target: "",
      alt: "Tecido Duna Cinza",
      title: "Tecido Duna Cinza",
      description: "",
   },
   {
      id: "img22",
      target: "",
      alt: "Tecido Duna Cru",
      title: "Tecido Duna Cru",
      description: "",
   },
   {
      id: "img23",
      target: "",
      alt: "Tecido Duna Fendi",
      title: "Tecido Duna Fendi",
      description: "",
   },
   {
      id: "img24",
      target: "",
      alt: "Tecido Duna Marinho",
      title: "Tecido Duna Marinho",
      description: "",
   },
   {
      id: "img25",
      target: "",
      alt: "Tecido Duna Terracota",
      title: "Tecido Duna Terracota",
      description: "",
   },
   {
      id: "img26",
      target: "",
      alt: "Tecido Herbal Azul",
      title: "Tecido Herbal Azul",
      description: "",
   },
   {
      id: "img27",
      target: "",
      alt: "Tecido Las Tunas Preto",
      title: "Tecido Las Tunas Preto",
      description: "",
   },
   {
      id: "img28",
      target: "",
      alt: "Tecido Maui Preto e Branco",
      title: "Tecido Maui Preto e Branco",
      description: "",
   },
   {
      id: "img29",
      target: "",
      alt: "Tecido Maui Turquesa",
      title: "Tecido Maui Turquesa",
      description: "",
   },
   {
      id: "img30",
      target: "",
      alt: "Tecido Acquabloc Alanta",
      title: "Tecido Acquabloc Alanta",
      description: "",
   }
]

export { cards }